import Flex from '@common-components/blocs/Flex'
import HeaderNavigate from '@components/tables/HeaderNavigate'
import { useGetFuneralNotPlanifiedQuery } from '@redux/features/funeral'
import { setActualFilters } from '@redux/features/funeral'
import { Button, Typography, DatePicker } from 'antd'
import { useRouter } from 'next/router'
import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { DATE_FORMAT, POLLING_INTERVAL } from '@common/constants'
import _ from 'lodash'

const DashboardHeaderCalendar = ({ start, setCursorDate }) => {
    const { push } = useRouter()
    const dispatch = useDispatch()

    const { data } = useGetFuneralNotPlanifiedQuery({}, { pollingInterval: POLLING_INTERVAL })

    const onNonePlannifiedFuneral = () => {
        dispatch(setActualFilters({ have_bury_date: false }))
        push({ pathname: '/funerals' })
    }

    const onDatePicked = (date) => {
        // When user clear the date we need to set null else we need get invalid Date
        _.isNil(date) ? setCursorDate({ start: null }) : setCursorDate({ start: dayjs(date).format(DATE_FORMAT.api) })
    }

    return (
        <Flex justify="between" className="calendar-header">
            <Flex>
                <Typography.Title level={3}>Obsèques planifiées</Typography.Title>
            </Flex>
            <Flex>
                <Button onClick={onNonePlannifiedFuneral} type="primary">
                    {data?.count} non planifié
                    <i className="fa-light fa-chevron-right" />
                </Button>

                <DatePicker value={!_.isNil(start) ? dayjs(start) : ''} format="DD/MM/YYYY" size="large" picker="week" onChange={onDatePicked} placeholder="Sélectionner une semaine" />

                <HeaderNavigate title="Créer une obsèque" path="/funerals/create" />
            </Flex>
        </Flex>
    )
}

export default memo(DashboardHeaderCalendar)
