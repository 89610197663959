////
export const locale = `fr-FR`
export const apiFormat = "YYYY-MM-DD"
export const frontFormat = "DD/MM/YYYY"

// ex : "mardi"
export function getDayText(date) {
  const options = { weekday: "long" }
  const today = new Date(date)
  return today.toLocaleDateString(locale, options)
}

// ex : "21"
export function getDayNumber(date) {
  const options = { day: "numeric" }
  const today = new Date(date)
  return today.toLocaleDateString(locale, options)
}

// ex : "décembre"
export function getMonthText(date) {
  const options = { month: "long" }
  const today = new Date(date)
  return today.toLocaleDateString(locale, options)
}

// ex : "21 décembre 2022"
export function getLongDate(timestamp) {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const today = new Date(timestamp)
  return today.toLocaleDateString(locale, options)
}

// ex : "21/12/2022"
export function getShortDate(timestamp) {
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  const today = new Date(timestamp)
  return today.toLocaleDateString(locale, options)
}

// ex : "21 décembre 2022 à 09h30"
export function getShortDateTime(timestamp) {
  const options = { year: "numeric", month: "long", day: "numeric" }
  const options2 = { hour: "numeric", minute: "numeric" }

  const today = new Date(timestamp)
  return today.toLocaleDateString(locale, options) + " - " + today.toLocaleTimeString(locale, options2)
}

// ex: "Il y a 3 heures"
export function getHistoryTime(date, complete = false) {
  let result = "Il y a "
  const timestamp_target = new Date(date).getTime()
  const timestamp_now = new Date().getTime()
  var diffMs = timestamp_now - timestamp_target
  // Si date postérieure à aujourd'hui
  if (diffMs < 0 || typeof diffMs !== "number") return "Date fournie postérieure à la date actuelle"
  // Si date valide
  else {
    const start = Math.floor(Math.abs(diffMs) / 1000)
    const mins = Math.floor(start / 60)
    const hours = Math.floor(mins / 60)
    const days = Math.floor(hours / 24)
    const secs = Math.floor(start % 60)
    if (complete) {
      if (days) result += days + " j "
      if (days < 7 && hours) result += (hours % 24) + " h "
      if (!days && hours && mins) result += String(mins % 60).padStart(2, "0")
      else if (!days && mins) result += (mins % 60) + " min "
      if (!days && !hours && secs) result += (mins % 60) + " s "
    } else {
      if (days) days > 1 ? (result += days + " jours") : (result = "hier")
      else if (hours) result += hours + " heure" + (hours > 1 ? "s" : "")
      else if (mins) result += mins + " minute" + (mins > 1 ? "s" : "")
      else if (secs) result += secs + " seconde" + (secs > 1 ? "s" : "")
    }
    return result
  }
}
