import { Space, Tag, Typography } from 'antd'

import Flex from '../../blocs/Flex'

const FooterTeam = () => {
    return (
        <Space className="calendarFooter team" size="large">
            <Flex className="absent" align="center">
                <Tag />
                <Typography.Text>Absent</Typography.Text>
            </Flex>
        </Space>
    )
}

export default FooterTeam
