import { Space, Tag } from 'antd'

import Flex from '../../blocs/Flex'

const FooterConvoy = () => {
    return (
        <Space className="calendarFooter convoy" size="large">
            <Flex className="full">
                <Tag />
                Complet
            </Flex>
            <Flex className="hightDemand">
                <Tag />
                Très demandé
            </Flex>
            <Flex className="available">
                <Tag />
                Disponible
            </Flex>
        </Space>
    )
}

export default FooterConvoy
