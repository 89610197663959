import FooterBasic from './core/FooterBasic'
import FooterConvoy from './core/FooterConvoy'
import FooterTeam from './core/FooterTeam'
import CalendarHeader from './core/Header'
import React from 'react'

const Calendar = ({
    cursorDate,
    setCursorDate,
    planning,
    children,
    withFooter = true,
    footerType = 'basic',
}) => {
    const getFooterFromType = React.useCallback(() => {
        if (footerType === 'basic') return <FooterBasic />
        if (footerType === 'team') return <FooterTeam />
        if (footerType === 'convoy') return <FooterConvoy />
    }, [footerType])
    return (
        <>
            <CalendarHeader
                cursorDate={cursorDate}
                setCursorDate={setCursorDate}
                planning={planning}
            />
            {children}

            {withFooter && getFooterFromType(footerType)}
        </>
    )
}

export default Calendar
