import { Button, Space, Typography } from 'antd'

import React from 'react'
import { getDayNumber, getDayText, getMonthText } from '../../../utils/dates'

const { Title } = Typography

const CalendarHeader = ({ cursorDate, setCursorDate, planning }) => {
    const prevView = () => {
        setCursorDate({
            ...cursorDate,
            start: planning?.period?.prev?.start,
            end: planning?.period?.prev?.end,
        })
    }

    const nextView = () => {
        setCursorDate({
            ...cursorDate,
            start: planning?.period?.next?.start,
            end: planning?.period?.next?.end,
        })
    }

    return (
        <div className="calendarHeader">
            <Button onClick={prevView} className="pagination-date" shape="circle">
                <i className="fa-light fa-chevron-left" />
            </Button>

            {planning?.days?.map((day, i) => {
                const isToday = day?.today

                const isHoliday = !!day?.planning?.find((halfDay) => halfDay.public_holiday)

                return (
                    <Space
                        key={i}
                        direction="vertical"
                        align="center"
                        className={[
                            isToday ? 'isToday' : '',
                            isHoliday ? 'isHoliday' : '',
                            getDayText(day?.date) === 'dimanche' ? 'isWeekEnd' : '',
                        ].join(' ')}
                    >
                        <Title level={5} children={getDayText(day?.date)} />
                        <Title level={2} strong children={getDayNumber(day?.date)} />
                        <Title level={4} strong children={getMonthText(day?.date)} />
                    </Space>
                )
            })}

            <Button onClick={nextView} className="pagination-date" shape="circle">
                <i className="fa-light fa-chevron-right" />
            </Button>
        </div>
    )
}

export default CalendarHeader
