import React from 'react'
import { Tooltip } from 'antd'
import Flex from '../blocs/Flex'
import { getLabelOrderFromType } from '../../utils/helpers'

const OrderSquares = ({ orders }) => {
    return (
        <Flex className="order-status-bars">
            {orders?.map((order) => (
                <Tooltip key={order.id} title={getLabelOrderFromType(order.type)}>
                    <span className={`status-bar ${order?.status}`} key={order?.id} />
                </Tooltip>
            ))}
        </Flex>
    )
}

export default OrderSquares
