import Flex from '@common-components/blocs/Flex'
import { Divider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import React from 'react'

import { CALENDAR_CONFIG, DATE_FORMAT } from '../../constants/index'

// key will be ref to half day for accessing to morning or afternoon data
// Ex: key an be equal to: "availabilities" (for convoy) or "middays" for (for funerals)
const CalendarBody = ({ renderItem, planning, dayAccesskey }) => {
    const cursorDate = React.useMemo(
        () => dayjs(planning?.period?.start).format(DATE_FORMAT.api),
        [planning?.period?.start]
    )

    const flatSevenDays = React.useMemo(() => {
        // Generate seven day and exclude sunday
        let days = []

        // Get the current date and add it to array of days
        let currentDate = dayjs(planning?.period?.start)

        days.push(currentDate.format('YYYY-MM-DD'))

        for (let i = 0; i < 5; i++) {
            // Add one day to the current date
            currentDate = currentDate.add(1, 'day')

            // If it's sunday exclude it
            if (currentDate.day() === 0) {
                currentDate = currentDate.add(1, 'day')
            }

            // Add the the next date to the array of days
            days.push(currentDate.format('YYYY-MM-DD'))
        }

        return days
    }, [cursorDate])

    return (
        <div className="calendarBody">
            {planning?.days?.map((day, i) => {
                const dayData = planning?.days?.find((e, index) => e?.date === flatSevenDays[i])

                const isHolidayData = dayData?.[dayAccesskey]?.find(
                    (halfDay) => halfDay.public_holiday
                )

                const isDatePassed = dayjs(day.date).isBefore(dayjs(), 'day')
                const classNameIsToday = dayData?.today ? 'isToday' : null
                const classNameIsHolliday = isHolidayData ? 'isHoliday' : null
                const classNameIsDatePassed = isDatePassed > 0 ? 'isDatePassed' : null

                const morningData = dayData?.[dayAccesskey]?.find(
                    (halfDay) => halfDay.am_pm === 'am'
                )
                const afternoonData = dayData?.[dayAccesskey]?.find(
                    (halfDay) => halfDay.am_pm === 'pm'
                )

                return (
                    <Flex
                        key={i}
                        direction="column"
                        align="center"
                        fullWidth
                        className={[
                            classNameIsToday,
                            classNameIsHolliday,
                            classNameIsDatePassed,
                            ' calendarDay',
                        ].join('')}
                    >
                        <div className="calendarPrintDate d-only-print">{dayjs(day.date).locale('fr').format('dddd DD MMMM YYYY')}</div>
                        <Flex
                            direction="column"
                            className="calendarMorning"
                            align="start"
                            fullWidth
                        >
                            <div className="calendarPrintAmPm d-only-print">matin</div>
                            {renderItem(morningData)}
                        </Flex>

                        <Divider />
                        <Flex
                            direction="column"
                            className="calendarAfternoon"
                            align="start"
                            fullWidth
                        >
                            <div className="calendarPrintAmPm d-only-print">après-midi</div>
                            {renderItem(afternoonData)}
                        </Flex>
                    </Flex>
                )
            })}
        </div>
    )
}
export default CalendarBody
