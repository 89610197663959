import { Button } from 'antd'
import { useRouter } from 'next/router'

// Component used to navigate to another page from list page (ex: add new funerals)
const HeaderNavigate = ({ title, path }) => {
    const { push } = useRouter()

    return (
        <Button
            onClick={() => push({ pathname: path })}
            type="secondary"
            icon={<i className="fa-light fa-circle-plus" />}
        >
            {title}
        </Button>
    )
}

export default HeaderNavigate
