import { Col, Row } from 'antd'

const fullSizeTemplate = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
}

export default function Grid({ className = '', children, style, template, gutter = 36 }) {
    const breakpoints = template?.[0] || fullSizeTemplate

    return (
        <Row
            style={style}
            className={className + ' containerGrid'}
            gutter={[0, 0]} //Array.isArray(gutter) ? gutter : [gutter, gutter]
        >
            {children?.length ? (
                children?.map((child, index) => {
                    const newBreakpoints =
                        template?.[index] || template?.[template?.length - 1] || breakpoints
                    return (
                        <Col key={index} {...newBreakpoints}>
                            {child}
                        </Col>
                    )
                })
            ) : (
                <Col {...breakpoints}>{children}</Col>
            )}
        </Row>
    )
}
