import { Space, Tag } from 'antd'

import Flex from '../../blocs/Flex'

const FooterBasic = () => {
    return (
        <Space className="calendarFooter basic" size="large">
            <Flex className="past">
                <Tag />
                Passé
            </Flex>
            <Flex className="today">
                <Tag />
                Aujourd'hui
            </Flex>
            <Flex className="ferialDay">
                <Tag />
                Jours fériés
            </Flex>
        </Space>
    )
}

export default FooterBasic
